var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('p',{staticClass:"c7"},[_c('span',{staticClass:"c8 c3"},[_vm._v(" Para más información acceda a la política de privacidad completa en el siguiente link "),_c('a',{attrs:{"href":_vm.rgpd.urlPolicy,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('common.politica_privacidad')))])])]),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"c4 c9"},[_c('span',{staticClass:"c0"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"c1"},[_c('span',{staticClass:"c0"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"c1"},[_c('span',{staticClass:"c0"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"c7"},[_c('span',{staticClass:"c3 c8"},[_vm._v(" La validez de esta receta expira a los 10 días naturales de la fecha prevista para la dispensación, o en su defecto de la fecha de prescripción. La medicación prescrita no superará los 3 meses de tratamiento. La receta es válida para una única dispensación en la farmacia. Esta receta podrá ser dispensada en cualquier oficina de farmacia del territorio nacional previa exhibición de su documento identificativo junto a la información de la cabecera o los códigos datamatrix que encontrará en este documento. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"c5"},[_c('span',{staticClass:"c8 c3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"c7"},[_c('span',{staticClass:"c8 c3"},[_vm._v(" En cumplimiento de lo establecido en el Reglamento (UE) 2016/679 General de Protección de Datos (RGPD) así como la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y Garantía de los Derechos Digitales(LOPDGDD), le informamos que los datos personales facilitados serán tratados por INTER PARTNER ASSISTANCE SERVICIOS ESPAÑA, S.A en calidad de Responsable, para la gestión y control de la prescripción médica. Puede ejercitar los derechos establecidos en la normativa de protección de datos personales enviando un correo electrónico a la dirección "),_c('a',{attrs:{"href":"mailto:protecciondedatos@axa-assistance.es"}},[_vm._v("protecciondedatos@axa-assistance.es.")]),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"c5"},[_c('span',{staticClass:"c8 c3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"c7"},[_c('span',{staticClass:"c3"},[_vm._v("Plataforma Homologada de prescripción por la OMC.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"c1"},[_c('span',{staticClass:"c0"})])
}]

export { render, staticRenderFns }