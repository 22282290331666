<template>
  <div>
    <p class="c4 c9"><span class="c0" /></p>
    <p class="c1"><span class="c0" /></p>
    <p class="c1"><span class="c0" /></p>
    <p class="c7">
      <span class="c3 c8">
        La validez de esta receta expira a los 10 días naturales de la fecha prevista para la dispensación, o en su
        defecto de la fecha de prescripción. La medicación prescrita no superará los 3 meses de tratamiento. La receta
        es válida para una única dispensación en la farmacia. Esta receta podrá ser dispensada en cualquier oficina de
        farmacia del territorio nacional previa exhibición de su documento identificativo junto a la información de la
        cabecera o los códigos datamatrix que encontrará en este documento.
      </span>
    </p>
    <p class="c5">
      <span class="c8 c3" />
    </p>
    <p class="c7">
      <span class="c8 c3">
        En cumplimiento de lo establecido en el Reglamento (UE) 2016/679 General de Protección de Datos (RGPD) así como
        la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y Garantía de los Derechos
        Digitales (LOPDGDD), le informamos que los datos personales facilitados serán tratados por DOKTOR ENLINEA en
        calidad de responsable, para la gestión y control de la prescripción médica.
      </span>
    </p>
    <p class="c5"><span class="c8 c3" /></p>
    <p class="c7">
      <span class="c8 c3">
        Puede ejercitar los derechos establecidos en la normativa de protección de datos personales enviando un correo
        electrónico a la dirección <a href="mailto:info@doktorenlinea.com">info@doktorenlinea.com.</a>
      </span>
    </p>
    <br />
    <p class="c7">
      <span class="c3"
        >Dicha solicitud deberá contener los siguientes datos: nombre y apellidos del usuario, domicilio a efectos de
        notificaciones, fotocopia del Documento Nacional de Identidad o Pasaporte, y petición en que se concreta la
        solicitud. En el caso de representación, deberá probarse la misma mediante documento fehaciente.</span
      >
    </p>
    <p class="c1"><span class="c0" /></p>
    <br />
  </div>
</template>

<script>
export default {
  name: 'DoctorenlineaLegal',
};
</script>

<style scoped></style>
