<template>
  <div>
    <p class="c4 c9"><span class="c0" /></p>
    <p class="c1"><span class="c0" /></p>
    <p class="c1"><span class="c0" /></p>
    <p class="c7">
      <span class="c3 c8">
        La validez de esta receta expira a los 10 días naturales de la fecha prevista para la dispensación, o en su
        defecto de la fecha de prescripción. La medicación prescrita no superará los 3 meses de tratamiento. La receta
        es válida para una única dispensación en la farmacia. Esta receta podrá ser dispensada en cualquier oficina de
        farmacia del territorio nacional previa exhibición de su documento identificativo junto a la información de la
        cabecera o los códigos datamatrix que encontrará en este documento.
      </span>
    </p>
    <p class="c5">
      <span class="c8 c3" />
    </p>
    <p class="c7">
      <span class="c8 c3">
        En cumplimiento de lo establecido en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos y
        Garantía de los Derechos Digitales (LOPDGDD) por la que se introduce en nuestro ordenamiento jurídico el
        Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo de 27 de abril de 2016 (RGPD), queda informado
        que los datos personales que nos ha facilitado serán incorporados al registro de actividades denominado
        <b>“Receta Médica Electrónica”</b> titularidad del prescriptor <b>(UNISALUD)</b>, para la gestión y control de
        la prescripción médica. Del mismo modo, serán incorporados en el correspondiente registro de actividades, por
        parte de la <b>farmacia dispensadora</b>, para dar cumplimiento a la obligación establecida en el RD 1718/2010.
      </span>
    </p>
    <p class="c5"><span class="c8 c3" /></p>
    <p class="c7">
      <span class="c8 c3">
        Puede ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento,
        portabilidad de datos y a no ser objeto de decisiones individualizadas automatizadas o notificándolo por escrito
        <b>ante el prescriptor</b> a las siguientes direcciones de correo electrónico:
        <a href="mailto:derechosprotecciondatos@unisalud.pro">derechosprotecciondatos@unisalud.pro.</a>
      </span>
    </p>
    <p class="c7">
      <span class="c3"
        >En este sentido, se le informa de la posibilidad de ejercitar estos derechos ante la correspondiente farmacia
        dispensadora.</span
      >
    </p>
    <p class="c7">
      <span class="c3"
        >Asimismo, se le informa de su derecho a reclamar ante la Autoridad de Control (Agencia Española de Protección
        de Datos: www.agpd.es).</span
      >
    </p>
    <p class="c7">
      <span class="c3"
        >Plataforma Homologada de prescripción por la OMC. Para más información acceda a
        <a href="https://www.unisalud.pro">www.unisalud.pro.</a></span
      >
    </p>
    <p class="c1"><span class="c0" /></p>
  </div>
</template>

<script>
export default {
  name: 'UnisaludLegal',
};
</script>

<style scoped></style>
