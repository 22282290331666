<template>
  <v-layout>
    <v-flex xs12>
      <v-layout wrap class="mb-5">
        <div v-if="dataOk" id="table-data" class="c6">
          <v-layout wrap class="mt-1 mb-3">
            <v-flex xs12 md3 offset-md9>
              <v-btn :style="butStyle" style="float: right;" @click.native="printPdf()"
                >{{ $t('recetas.imprimir') }} <v-icon class="icon">mdi-printer</v-icon></v-btn
              >
            </v-flex>
          </v-layout>
          <p class="c4">
            <img :src="appLogoPrimary" class="logo" /><br />
            <span class="c8 c22">HOJA DE MEDICACI&Oacute;N ACTIVA E INFORMACI&Oacute;N AL PACIENTE</span>
          </p>
          <p class="c4">
            <span class="c0">RECETA ELECTR&Oacute;NICA</span>
          </p>
          <p class="c4 c9">
            <span class="c0" />
          </p>
          <a id="t.ada2fd02f8170d3bc880f68c839b8e81ac344777" />
          <a id="t.0" />
          <table class="c13">
            <tbody>
              <tr class="c23 desktop">
                <td class="c16" colspan="1" rowspan="1">
                  <p class="c6"><span class="c2">ID REPOSITORIO</span></p>
                  <p class="c6">
                    <span> {{ prescription.repo_id }}</span>
                  </p>
                </td>
                <td class="c16" colspan="1" rowspan="1">
                  <p class="c6"><span class="c2">ID ACCESSO</span></p>
                  <p class="c6">
                    <span class="c0 nowrap">{{ prescription.patient_repo_id }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c23 mobile">
                <td class="c16" colspan="2" rowspan="1">
                  <p class="c6"><span class="c2">ID REPOSITORIO</span></p>
                  <p class="c6">
                    <span> {{ prescription.repo_id }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c23 mobile">
                <td class="c16" colspan="2" rowspan="1">
                  <p class="c6"><span class="c2">ID ACCESSO</span></p>
                  <p class="c6">
                    <span class="c0 nowrap">{{ prescription.patient_repo_id }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c23 desktop">
                <td colspan="1" rowspan="1">
                  <p class="c6"><span class="c2">FECHA PRESCRIPCIÓN:</span></p>
                  <p class="c6">
                    <span> {{ emmit_date }}</span>
                  </p>
                </td>
                <td v-if="prescription.id_hip" colspan="1" rowspan="1">
                  <p class="c6"><span class="c2">ID HIP:</span></p>
                  <p class="c6">
                    <span class="c0 nowrap">{{ prescription.id_hip }}</span>
                  </p>
                </td>
              </tr>

              <tr class="c23 mobile">
                <td class="c16" colspan="2" rowspan="1">
                  <p class="c6"><span class="c2">FECHA PRESCRIPCIÓN:</span></p>
                  <p class="c6">
                    <span> {{ emmit_date }}</span>
                  </p>
                </td>
              </tr>
              <tr v-if="prescription.id_hip" class="c23 mobile">
                <td class="c16" colspan="2" rowspan="1">
                  <p class="c6"><span class="c2">ID HIP:</span></p>
                  <p class="c6">
                    <span class="c0 nowrap">{{ prescription.id_hip }}</span>
                  </p>
                </td>
              </tr>

              <tr v-if="prescription.identification_datamatrix" class="c23">
                <td class="separate c21 text-right" colspan="2" rowspan="1">
                  <div class="mt-1">
                    <!-- eslint-disable vue/no-v-html -->
                    <!--span class="datamatrix-img mt-3" v-html="prescription.identification_datamatrix" /-->
                    <!-- eslint-enable vue/no-v-html -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="c4 c9"><span class="c0" /></p>
          <p class="c4"><span class="c0">PACIENTE</span></p>
          <p class="c4">
            <span class="c0">{{ prescription.patient_name }}</span>
          </p>
          <p class="c4">
            <span class="c0">DNI: {{ prescription.patient_dni }}</span>
          </p>
          <p class="c4">
            <span class="c0">Fecha de nacimiento: {{ prescription.patient_birthdate }}</span>
          </p>
          <p class="c4 c9"><span class="c0" /></p>
          <a id="t.0bceb1bb209023d781e9ffa1dd7c40e88b22c717" />
          <a id="t.1" />
          <table v-for="(medicine, index) in medicines" :key="index" class="c13">
            <tbody>
              <tr class="c11">
                <td class="c19" colspan="2" rowspan="1">
                  <p class="c6 c9">
                    <span class="c0" />
                  </p>
                  <p v-if="medicine.code_national" class="c6 mb-2">
                    <span class="">Código: {{ medicine.code_national }}</span>
                  </p>
                  <p class="c6">
                    <span class="c0">{{ medicine.name }}</span>
                  </p>
                </td>
              </tr>
              <tr class="c12">
                <td class="c21" colspan="2" rowspan="1">
                  <div v-if="medicine.is_master_formula" class="c6">
                    <span class="c2">DOSIFICACIÓN</span><br />
                    <span class="c2 text-lowercase">{{ medicine.dosage }}</span>
                  </div>
                  <p class="c6">
                    <span class="c2 text-lowercase">
                      {{ medicine.posology_text }}
                      <span v-if="medicine.date_start">
                        {{ $t('recipe.from') }} {{ medicine.date_start }} {{ $t('recipe.to') }}
                        {{ medicine.date_end }}
                      </span>
                    </span>
                  </p>
                  <br v-if="medicine.is_master_formula" />
                </td>
              </tr>
              <tr class="c12">
                <td class="c10" colspan="1" rowspan="1">
                  <p class="c6 c9"><span class="c2" /></p>
                  <p class="c6"><span class="c2">INSTRUCCIONES PARA EL PACIENTE</span></p>
                  <p class="c6">
                    <span class="c2">{{ medicine.comments }}</span>
                  </p>
                  <p class="c6 c9"><span class="c2" /></p>
                  <p v-if="medicine.pharmacist_warnings" class="c6">
                    <span class="c2 text-uppercase">{{ $t('recipe.pharmacist_warnings') }}</span>
                  </p>
                  <p class="c6">
                    <span class="c2">{{ medicine.pharmacist_warnings }}</span>
                  </p>
                  <p class="c6 c9"><span class="c2" /></p>
                  <p class="c6"><span class="c2">PRESCRIPTOR</span></p>
                  <p class="c6">
                    <span class="c2">{{ prescriptor.name }} {{ prescriptor.surname }}</span>
                  </p>
                  <p class="c6">
                    <span class="c2">Nº Colegiado: {{ prescriptor.collegiate }}</span>
                  </p>
                  <p class="c6 c9"><span class="c2" /></p>
                  <p class="c6">
                    <span class="c2">{{ prescriptor.address }}</span>
                  </p>
                  <p class="c6 c9"><span class="c2" /></p>
                </td>
                <td class="c17" colspan="1" rowspan="1">
                  <p class="c20">
                    <span class="c2">
                      <!-- eslint-disable vue/no-v-html -->
                      <span
                        v-if="medicine.svg_datamatrix !== ''"
                        class="datamatrix-img mt-3"
                        v-html="medicine.svg_datamatrix"
                      />
                      <!-- eslint-enable vue/no-v-html -->
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="showDoclineLegal">
            <p class="c4 c9"><span class="c0" /></p>
            <p class="c1"><span class="c0" /></p>
            <p class="c1"><span class="c0" /></p>
            <p class="c7">
              <span class="c3 c8"
                >Recuerde que la primera vez que retire un medicamento de su tratamiento en la farmacia debe hacerlo
                dentro de los primeros 10 d&iacute;as desde la fecha de inicio de su tratamiento.</span
              >
            </p>
            <p class="c5"><span class="c8 c3" /></p>
            <p class="c7">
              <span class="c8 c3"
                >El paciente autoriza el acceso por el farmac&eacute;utico a los tratamientos incluidos en esta
                relaci&oacute;n. El paciente conservar&aacute; este documento de informaci&oacute;n durante el periodo
                de validez del tratamiento. En cumplimiento del RGPD y de la LOPDGDD, se informa de que estos datos
                ser&aacute;n incorporados al fichero Docline &nbsp;para la gesti&oacute;n y control de la
                prestaci&oacute;n farmac&eacute;utica, cuyo &oacute;rgano responsable es Aplicaciones Salud S.L.. Puede
                ejercer sus derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n, oposici&oacute;n,
                limitaci&oacute;n del tratamiento y portabilidad en rgpd@docline.es.</span
              >
            </p>
            <p class="c5"><span class="c8 c3" /></p>
            <p class="c7">
              <span class="c8 c3"
                >Esta hoja de informaci&oacute;n al paciente ha sido generada con el sistema de Receta M&eacute;dica
                Electr&oacute;nica Docline, que le permitir&aacute; retirar la medicaci&oacute;n que le corresponda en
                la farmacia mediante su documento identificativo junto a la informaci&oacute;n de la cabecera o los
                c&oacute;digos datamatrix que encontrar&aacute; en esta hoja.</span
              >
            </p>
            <p class="c7">
              <span class="c3"
                >Plataforma Homologada &nbsp;de prescripci&oacute;n por la OMC. Para m&aacute;s informaci&oacute;n
                visite docline.es.</span
              >
            </p>
            <p class="c1"><span class="c0" /></p>
          </div>
          <unisalud-legal v-if="showUnisaludLegal" />
          <doctor-clic-legal v-if="showDoctorClicLegal" />
          <doctorenlinea-legal v-if="showBioksanLegal" />
          <vithas-legal v-if="showVithasLegal" />
          <p class="c7">
            <span class="c3">Hoja de información generada el {{ prescription.timestamp }}</span>
          </p>

          <p v-if="activeMedicines.length > 0" class="c4 mt-10 mb-3 underline">
            <span class="c8 c22">LISTADO DE MEDICAMENTOS ACTIVOS DEL PACIENTE</span>
          </p>

          <template v-for="(activeMedicine, index) in activeMedicines">
            <div :key="index" class="row med-activa">
              <div class="col-12 mt-3">
                <span class="darken">{{ activeMedicine.medicine.name }}</span>
                <v-chip v-if="activeMedicine.medicine.code_national" x-small class="float-right"
                  >Cód. {{ activeMedicine.medicine.code_national }}</v-chip
                >
              </div>
              <div class="col-6">
                <p class="mb-1">
                  <span v-if="activeMedicine.medicine.date_start" class="lighten">F. Inicio</span
                  ><span class="ml-2">{{ activeMedicine.medicine.date_start }}</span>
                </p>
                <p class="mb-1">
                  <span class="lighten">Prescriptor</span
                  ><span class="ml-2"
                    >{{ activeMedicine.prescriptor.name }} {{ activeMedicine.prescriptor.surname }}</span
                  >
                </p>
              </div>
              <div class="col-6">
                <p class="mb-1">
                  <span v-if="activeMedicine.medicine.date_end" class="lighten">F. Fin</span
                  ><span class="ml-2">{{ activeMedicine.medicine.date_end }}</span>
                </p>
                <p class="mb-1">
                  <span class="lighten">Nº colegiado</span
                  ><span class="ml-2">{{ activeMedicine.prescriptor.collegiate }}</span>
                </p>
              </div>
            </div>
          </template>
        </div>
        <p v-else>No hay datos</p>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import UnisaludLegal from '@/components/hip/UnisaludLegal.vue';
import DoctorClicLegal from '@/components/hip/DoctorClicLegal.vue';
import { datamatrixUrl } from '../config/config';
import DoctorenlineaLegal from '../components/hip/DoctorenlineaLegal.vue';
import VithasLegal from '../components/hip/VithasLegal.vue';

export default {
  components: { UnisaludLegal, DoctorClicLegal, DoctorenlineaLegal, VithasLegal },
  data: () => ({
    dataOk: true,
    prescription: {
      repo_id: '',
      patient_repo_id: '',
      patient_name: '',
      treatment_days: 0,
      treatment_end: '',
      treatment_start: '',
    },
    emmit_date: '',
    prescriptor: {
      name: '',
      surname: '',
      collegiate: '',
    },
    medicines: [],
    activeMedicines: [],
    showDoclineLegal: true,
    showUnisaludLegal: false,
    showDoctorClicLegal: false,
    showBioksanLegal: false,
    showVithasLegal: false,
    butStyle: 'background-color: #1867c0; border-color: #1867c0; color: #ffffff;',
  }),

  computed: {
    ...mapState({
      appLogoPrimary: state => state.app.images.logoPrimary,
      appDisplayName: state => state.app.displayName,
      appThemePrimary: state => state.app.styles.themePrimary,
    }),
  },

  mounted() {
    this.setButtonColor();
    this.getDatamatrix();
    this.hideSupport();
    this.showLegalFooter();
  },

  methods: {
    getDatamatrix() {
      try {
        const code = this.$route.params.code;
        this.$http.post(datamatrixUrl, { code: code }).then(response => {
          if (response.status === 200 && parseInt(response.body.status) === 1) {
            this.medicines = response.body.medicines;
            this.prescriptor = response.body.prescriptor;
            this.prescription = response.body.prescription;
            this.prescription.repo_id = response.body.medicines[0].repository_id;
            this.emmit_date = response.body.medicines[0].emmit_date;
            this.prescription.patient_repo_id = response.body.prescription.patient_repo_id;
            this.activeMedicines = response.body.active_medicines;

            this.medicines.forEach(subitem => {
              subitem.posology_text = this.getPosologyText(subitem);
            });
          } else {
            this.dataOk = false;
          }
        });
      } catch (e) {
        throw new Error(e.message);
      }
    },

    hideSupport() {
      setTimeout(function() {
        const supportElem = document.getElementById('beacon-container-body');
        if (typeof supportElem !== 'undefined' && supportElem !== null) {
          supportElem.style.display = 'none';
        }
      }, 1000);
    },

    getPosologyText(medicine) {
      const textUnits = medicine.guideline > 1 ? this.$t('recipe.units') : this.$t('recipe.unit');
      const posology_measure =
        medicine.posology > 1
          ? medicine.posology_measure
          : medicine.posology_measure.substring(0, medicine.posology_measure.length - 1);
      const units = medicine.is_master_formula ? medicine.amount : textUnits;
      const durationMeasure = this.$te(`recipe.${medicine.duration_measure}`)
        ? this.$t(`recipe.${medicine.duration_measure}`)
        : medicine.duration_measure;
      return (
        medicine.guideline +
        ' ' +
        units +
        ' ' +
        this.$t('recipe.every') +
        ' ' +
        medicine.posology +
        ' ' +
        posology_measure +
        ' ' +
        this.$t('recipe.during') +
        ' ' +
        medicine.duration +
        ' ' +
        durationMeasure
      );
    },

    printPdf() {
      document.execCommand('print') || window.print();
    },

    showLegalFooter() {
      if (this.appDisplayName.toUpperCase() === 'UNISALUD') {
        this.showUnisaludLegal = true;
        this.showDoclineLegal = false;
      } else if (this.appDisplayName.toUpperCase() === 'DOCTOR CLIC') {
        this.showDoctorClicLegal = true;
        this.showDoclineLegal = false;
      } else if (this.appDisplayName.toUpperCase() === 'DOKTOR ENLINEA') {
        this.showBioksanLegal = true;
        this.showDoclineLegal = false;
      } else if (this.appDisplayName.toUpperCase() === 'VITHAS') {
        this.showVithasLegal = true;
        this.showDoclineLegal = false;
      }
    },

    setButtonColor() {
      if (this.appThemePrimary) {
        this.butStyle =
          'background-color: ' + this.appThemePrimary + '; border-color: ' + this.appThemePrimary + '; color: #ffffff;';
      }
    },
  },
};
</script>
<style>
.datamatrix-img {
  text-align: center;
}

svg {
  width: 160px;
}

.primary {
  background-color: #1867c0;
  border-color: #1867c0;
  color: #ffffff;
}

.icon {
  margin-left: 10px;
}

@import url('https://themes.googleusercontent.com/fonts/css?kit=dpiI8CyVsrzWsJLBFKehGpLhv3qFjX7dUn1mYxfCXhI');
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c16 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 2.2pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 263.5pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c17 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 204.8pt;
  border-top-color: #999999;
  border-bottom-style: solid;
}
.c10 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 321.8pt;
  border-top-color: #999999;
  border-bottom-style: solid;
}
.c19 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 2.2pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 526.5pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c14 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 2.2pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 204.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c15 {
  border-right-style: solid;
  border-bottom-color: #999999;
  border-top-width: 0pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 204.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c21 {
  border-right-style: solid;
  border-bottom-color: #999999;
  border-top-width: 0pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 321.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c5 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
  margin-right: 2.4pt;
  height: 12pt;
}
.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Roboto';
  font-style: normal;
}
.c7 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
  margin-right: 2.4pt;
}
.c1 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt;
}
.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Roboto';
  font-style: normal;
}
.c8 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: 'Roboto';
  font-style: normal;
}
.c13 {
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.c6 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c20 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: right;
}
.c4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  text-align: left;
}
.c18 {
  background-color: #ffffff;
  max-width: 527pt;
  padding: 42.5pt 42.5pt 42.5pt 42.5pt;
}
.c9 {
  height: 12pt;
}
.c23 {
  height: 39.7pt;
}
.c11 {
  height: 42.5pt;
}
.c12 {
  height: 28.3pt;
}
.c3 {
  font-size: 8pt;
}
.c22 {
  font-size: 13pt;
}

.separate {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1.2pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 2.2pt;
  width: 263.5pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: 'Roboto';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 12pt;
  font-family: 'Roboto';
}
p {
  margin: 0;
  color: #000000;
  font-size: 12pt;
  font-family: 'Roboto';
}
h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: 'Roboto';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: 'Roboto';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Roboto';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Roboto';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Roboto';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Roboto';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

#table-data {
  margin: 0 auto;
}

@media (min-width: 1200px) {
  #table-data {
    width: 650px !important;
  }
}

@media (max-width: 750px) {
  #table-data {
    margin-left: 10px;
    margin-right: 10px;
    max-width: 712px !important;
  }
}

@media (max-width: 1199px) {
  #table-data {
    max-width: 712px !important;
  }
}

.nowrap {
  white-space: nowrap;
}

.mobile {
  display: none;
}

.logo {
  width: 30%;
  margin-bottom: 1em;
}

@media (max-width: 576px) {
  .mobile {
    display: inline-block;
  }
  .desktop {
    display: none;
  }

  .c16 {
    width: 574px !important;
  }
  .logo {
    width: 40%;
  }
}

.v-application #table-data p {
  margin-bottom: unset;
}

.med-activa {
  border-bottom: 1px solid #808080;
}

.lighten {
  color: #5d5d5d;
}

.underline {
  border-bottom-style: solid;
  border-bottom-color: #000000;
  border-bottom-width: 2.2pt;
}
</style>
