<template>
  <div class="mt-3">
    <br /><br /><br />
    <p class="c7 mt-5">
      <span class="c3 c8 "
        >Recuerde que la primera vez que retire un medicamento de su tratamiento en la farmacia debe hacerlo dentro de
        los primeros 10 días desde la fecha de inicio de su tratamiento.</span
      >
    </p>
    <p class="c5"><span class="c8 c3" /></p>
    <p class="c7">
      <span class="c8 c3"
        >Tenga en cuenta que la retirada del medicamento con el uso de la receta electrónica implica el tratamiento de
        sus datos por parte de la oficina de farmacia en la que lo retire. El paciente conservará este documento de
        información durante el periodo de validez del tratamiento. En cumplimiento del RGPD y de la LOPDGDD, se le
        informa que sus datos personales serán tratados con la exclusiva finalidad de facilitarle asistencia médica y
        farmacéutica al paciente, en el marco del Sistema de Receta Electrónica Privada, como sistema de información
        basado en receta médica en soporte electrónico, establecido por los Consejos Generales de Farmacéuticos,
        Médicos, Odontólogos y Podólogos al amparo de lo dispuesto en el artículo 14.4. del RD 1718/2010. En este
        sentido, Vithas Sanidad, S.L. actúa como responsable del tratamiento en relación a sus datos clínicos, pudiendo
        acceder a los mismos las oficinas de farmacia para poder garantizar la correcta asistencia farmacéutica y la
        organización farmacéutica colegial para la interoperabilidad que garantiza su derecho como paciente a que los
        tratamientos que le hayan sido prescritos en receta médica privada electrónica puedan ser dispensados en
        cualquier oficina de farmacia del territorio nacional. Puede ejercer sus derechos de acceso, rectificación,
        cancelación, oposición, limitación del tratamiento y portabilidad en proteccion.datos@vithas.es
      </span>
    </p>
    <p class="c5"><span class="c8 c3" /></p>
    <p class="c7">
      <span class="c8 c3"
        >Esta hoja de información al paciente ha sido generada con el sistema de Receta Médica Electrónica Docline, que
        le permitirá retirar la medicación que le corresponda en la farmacia mediante su documento identificativo junto
        a la información de la cabecera o los códigos datamatrix que encontrará en esta hoja.</span
      >
    </p>
    <p class="c7">
      <span class="c3">Plataforma Homologada de prescripción por la OMC. Para más información visite docline.es.</span>
    </p>
    <p class="c1"><span class="c0" /></p>
  </div>
</template>

<script>
export default {
  name: 'UnisaludLegal',
};
</script>

<style scoped></style>
