import { render, staticRenderFns } from "./DoctorClicLegal.vue?vue&type=template&id=575a2ff2&scoped=true"
import script from "./DoctorClicLegal.vue?vue&type=script&lang=js"
export * from "./DoctorClicLegal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "575a2ff2",
  null
  
)

export default component.exports